import React, {useEffect, useState} from 'react';
import $ from "jquery";
import icon_page_first from "../images/servicecenter_img/Icon-page_first.png";
import icon_page_last from "../images/servicecenter_img/Icon-page_last.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import AddToken from "../authentication/token";
import InGameManToManContent from "./manToManContent";

const renderDivision = (division) => {
    switch (division) {
        case 0:
            return '[설치/실행]';
        case 1:
            return '[계정]';
        case 2:
            return '[결제]';
        case 3:
            return '[이벤트]';
        case 4:
            return '[전체]';
        case 5:
            return '[기타]';
        default:
            return '[알 수 없음]';
    }
};


const ManToManReceipt = ({setPage}) => {
    const [service, setService] = useState("4");
    const [contents, setContents] = useState('');
    const [title, setTitle] = useState('');
    const [messagePopup, setMessagePopup] = useState("");


    const handleServiceChange = (event) => {
        setService(event.target.value);
    };

    const handleContentsChange = (e) => {
        let inputVal = e.target.value.replace(/^\s+/, '').replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '');
        if (inputVal.length <= 1000) {
            setContents(inputVal);
        }
    };

    const handleTitleChange = (e) => {
       let inputVal = e.target.value.replace(/^\s+/, '').replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '');
       setTitle(inputVal);
    };


    const inqueryRequest = async () => {
        if (!title && !contents){
            setMessagePopup("문의하실 제목과 상세 내용을 입력해 주세요.");
            return;
        }
        if (!title){
            return setMessagePopup("문의하실 제목을 입력해 주세요.");
        }
        else if (!contents){
            return setMessagePopup("문의하실 내용을 입력해 주세요.");
        }

        const data = {
            division: service,
            title: title,
            contents: contents
        };
        try {
            const response = await axios.post(
                baseUrl + 'service_center/inquiry_request/',
                data,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (response.status === 201) {
                $('.game_sub_main_title').removeClass('active');
                $('#statement').addClass('active');
                setPage("statement");
            }
        } catch (e) {
            alert("failed");
        }
    };


    return (
        <>
            <div className="game_man_contents_box" style={{backgroundColor: "#333",color: "white"}}>
                <div className="game_man_contents">
                    <div className="game_man_contents_inner_left_box">
                        <em>서비스 선택</em>
                    </div>

                    <div className="game_contents_inner_right_box">
                        <select
                            name="division"
                            className="game_man_drop_box_select"
                            value={service}
                            onChange={handleServiceChange}
                        >
                            <option value="4">전체</option>
                            <option value="0">설치/실행</option>
                            <option value="1">계정</option>
                            <option value="2">결제</option>
                            <option value="3">이벤트</option>
                            <option value="5">기타</option>
                        </select>
                    </div>
                </div>

                <div className="game_man_contents">
                    <div className="game_man_contents_inner_left_box">
                        <em>제 목</em>
                    </div>

                    <div className="game_man_password_box">
                        <div className="man_password_input_box">
                            <input
                                id="ManToManTitle"
                                type="text"
                                name="title"
                                maxLength="30"
                                autoComplete="off"
                                value={title}
                                onChange={handleTitleChange}
                                style={{width: '98%', color: "white"}}
                            />
                        </div>
                    </div>

                </div>

                <div className="game_man_contents" style={{height: '250px'}}>
                    <div className="game_man_contents_inner_left_box">
                        <em>내 용</em>
                    </div>

                    <div className="man_txt_div" style={{backgroundColor: "black"}}>
                        <textarea
                            maxLength="1000"
                            className="game_man_text_area"
                            placeholder="최대 1000자까지 가능합니다."
                            value={contents}
                            onChange={handleContentsChange}>
                        </textarea>
                    </div>

                </div>
            </div>

            <div className="ok_no_box_button">
                <button className="in_game_ok_no_button" onClick={inqueryRequest}>
                    확인
                </button>
            </div>

            {messagePopup && (
                <div className="game_service_center_popup">
                    <div className="game_notice_popup_content" style={{width: "350px"}}>
                        <div className="user_restrict_popup_div_3" style={{marginTop: "35px"}}>
                            {messagePopup}
                        </div>

                        <div className="ok_no_box_button" style={{paddingTop: "20px"}}>
                            <button className="in_game_ok_no_button" style={{marginRight: "unset"}} onClick={() => setMessagePopup('')}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


const ManToManStatement = () => {
    const [customerData, setCustomerData] = useState([]);
    const [customerDataCount, setCustomerDataCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setCount] = useState([]);
    const [requestId, setRequestId] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const navigate = useNavigate();

    function handlePrevPageChange() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            customerService((pageNumber - 2) * 5);
        }
    }

    function handleNextPageChange() {
        if (pageNumber < customerDataCount) {
            setPageNumber(pageNumber + 1);
            customerService(pageNumber * 5);
        }
    }

    const handlePageClick = (index) => {
        customerService(index *  5);
        setPageNumber(index + 1);
    };

    function content(request_id) {
        setRequestId(request_id);
        setShowContent(true);
    }

    const customerService = async (offset) => {
        try {
            const response = await axios.get(baseUrl + 'service_center/customer_service/?limit=5&offset=' + offset, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (response.status === 200) {
                setCustomerData(response.data.results);
                const c = Math.ceil(response.data.count / 5)

                setCustomerDataCount(c)
                setCount(Array(c).fill(c));
            }
        } catch (e) {
            navigate("/ingame/service_center/inquiry");
        }
    }


    useEffect(() => {
        customerService(0);
    }, []);

    return (
        <>
            {showContent ?
                <InGameManToManContent requestId={requestId} onClose={() => setShowContent(false)} />
                :
                <>
                    <div className="game_billionaire_contents_box">
                        <div className="game_billionaire_contents_title" style={{width: '156px'}}>
                            처리현황
                        </div>
                        <div className="game_billionaire_contents_title" style={{width: '566px'}}>
                            제목
                        </div>
                        <div className="game_billionaire_contents_title" style={{width: '110px'}}>
                            분류
                        </div>
                        <div className="game_billionaire_contents_title" style={{width: '158px'}}>
                            접수일자
                        </div>
                    </div>

                    {customerData.map((data) => (
                        <div className="game_billionaire_contents_box_2" onClick={event => content(data.cs_id)}>
                            <div className="game_billionaire_contents_title" style={{width: '156px'}}>
                                {data.answer === null ? '답변대기' : <a style={{color: '#15a3e1'}}>답변완료</a>}
                            </div>
                            <div className="game_billionaire_contents_title" style={{width: '566px'}}>
                                {data.title}&nbsp;
                            </div>
                            <div className="game_billionaire_contents_title" style={{width: '110px'}}>
                                {renderDivision(data.division)}
                            </div>
                            <div className="game_billionaire_contents_title" style={{width: '158px'}}>
                                {data.writeDate.split('T')[0]}
                            </div>
                        </div>
                    ))}

                    <div className="game_notice_paging_box">

                        <div className="service_paging_button">
                            <div onClick={handlePrevPageChange} style={{borderRight: '2px solid rgb(78, 78, 78)'}}>
                                <img src={icon_page_first} alt=""/>
                            </div>
                        </div>

                        <div>
                            {count.map((value, index) => {
                                return (
                                    <>
                                        <button
                                            id={`${index}`}
                                            className={`qa_pagination_buttons_2 ${index + 1 === pageNumber ? "qa_pagination_buttons": ""}`}
                                            onClick={() => handlePageClick(index)}
                                            style={{color: "white"}}
                                        >
                                            {index + 1}
                                        </button>
                                    </>
                                )

                            })}
                        </div>


                        <div className="service_paging_button">
                            <div onClick={handleNextPageChange} style={{borderLeft: '2px solid rgb(78, 78, 78)'}}>
                                <img src={icon_page_last} alt=""/>
                            </div>
                        </div>

                    </div>
                </>
            }
        </>
    );

};

const InGameInquiry = () => {
    const [page, setPage] = useState("receipt");
    const [isContentExist, setIsContentExist] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');


    const changeTab = (e, showPage) => {
        $('.game_sub_main_title').removeClass('active');
        $(e.target).addClass('active');
        setPage(showPage);

        if(showPage === "statement"){
            setIsContentExist(false)
            contentRead();
        }
    }


    const contentRead = async () => {
        try {
            await axios.get(baseUrl + 'service_center/customer_service_content_read', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
        } catch {
        }
    }


    const checkDataExist = async () => {
        try {
            await axios.get(baseUrl + 'service_center/customer_service_check_content', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
            setIsContentExist(true)
        } catch (e) {
            setIsContentExist(false)
        }
    }

    useEffect(() => {
        $('body').css({
            'background': 'black',
            'zoom': '110%'
        });

        if(token){
            AddToken(token);
            setPage("receipt");
        }

        checkDataExist()
    }, []);

    return (
        <>
            <div className="game_service_center_warp">
                <div className="game_service_game_center">
                    <div className="game_center_warp_inner">
 
                        <div className="game_sub_main_title_box">
                            <div id="receipt" className="game_sub_main_title active" onClick={e => changeTab(e,"receipt")}>1:1문의 접수</div>
                            <div id="statement" className="game_sub_main_title" onClick={e => changeTab(e,"statement")}>접수 내역</div>
                            {page === "receipt" && isContentExist && (
                                <div className="red_dot"/>
                            )}
                        </div>

                        {page === "receipt" && (
                            <ManToManReceipt setPage={setPage}/>
                        )}

                        {page === "statement" && (
                            <ManToManStatement/>
                        )}

                    </div>
                </div>
            </div>
        </>
    );


};

export default InGameInquiry;
